<template>
    <Button
        :label="getLabel"
        :icon="iconName"
        iconPos="right"
        :loading="loading"
        :class="classes"
        :disabled="disabled"
        @click="handleClick"
    />
</template>

<script>
import Button from 'primevue/button'

export default {
    name: 'CoreButtonPrimary',

    components: { Button },

    props: {
        label: {
            type: String,
            default: '',
        },

        icon: {
            type: String,
            default: null,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        colorPrimary: {
            type: Boolean,
            default: false,
        },

        floatRight: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                'core-button-primary': true,
                'core-button-primary--disabled': this.disabled,
                'core-button-primary--color-primary': this.colorPrimary,
                'core-button-primary--float-right': this.floatRight,
                'p-button-rounded': true,
            }
        },

        getLabel() {
            return this.$t(this.label)
        },

        iconName() {
            return `pi ${this.icon}`
        },
    },

    methods: {
        handleClick() {
            if (!this.disabled && !this.loading) {
                this.$emit('click')
            }
        },
    },
}
</script>

<style lang="scss">
.p-button.core-button-primary {
    background: $cs;
    padding: 8px 20px;
    border: 1px solid $cs;
    display: flex;

    & > .p-button-label {
        color: $cn-10;
        font-weight: 300;
        font-size: 1.6rem;
    }

    // icon

    & > .p-button-icon {
        color: $cn-10;
        font-size: 1.2rem;
        margin-left: 0.8rem;
    }

    & > .p-button-icon-right {
        order: 1;
        align-self: center;
    }

    // hover

    &.p-button:enabled:hover {
        cursor: pointer;
        background: $cs;

        & > .p-button-label {
            color: $cp-75;
        }

        & > .p-button-icon {
            color: $cp-75;
        }
    }

    // focus

    &.p-button:focus {
        background: $cs;
        outline: 2px solid $cp-75;
    }

    // disabled

    &.core-button-primary--disabled.p-component:disabled {
        opacity: 1;
    }

    &.core-button-primary--disabled.p-button {
        cursor: not-allowed;
        background: $cn-75;
        border: 1px solid $cn-75;

        & > .p-button-label {
            color: $cn-10;
        }

        & > .p-button-icon {
            color: $cn-10;
        }
    }

    &.core-button-primary--disabled.p-button:hover {
        cursor: not-allowed;
        background: $cn-75;
        border: 1px solid $cn-75;

        & > .p-button-label {
            color: $cn-10;
        }

        & > .p-button-icon {
            color: $cn-10;
        }
    }
    &.core-button-primary--disabled.p-button:focus {
        background: $cn-75;
        outline: none;
    }
}

.p-button.core-button-primary.core-button-primary--float-right {
    float: right;
}

.p-button.core-button-primary.core-button-primary--color-primary {
    background: $cp;
    border: 1px solid $cp;

    & > .p-button-label {
        color: $cs;
    }

    // icon

    & > .p-button-icon {
        color: $cs;
    }

    &.p-button:enabled:hover {
        cursor: pointer;
        background: $cp-75;

        & > .p-button-label {
            color: $cs;
        }

        & > .p-button-icon {
            color: $cs;
        }
    }

    &.p-button:focus {
        background: $cp-75;
        outline: 2px solid $cp-25;
    }

    &.core-button-primary--disabled {
        background-color: $cn-75;
        border: 1px solid $cn;

        & .p-button-label,
        & .p-button-icon.p-button-icon-right {
            color: $cn-10;
        }
    }
}

.p-button.core-button-primary.p-button-danger {
    &.p-button:enabled:hover {
        cursor: pointer;
        background: darken($color-error, 10%);

        & > .p-button-label {
            color: $cn-10;
        }

        & > .p-button-icon {
            color: $cn-10;
        }
    }

    &.p-button:focus {
        background: $color-error;
        outline: 2px solid darken($color-error, 10%);
    }
}
</style>
