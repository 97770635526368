<template>
    <div class="equipment-connectivity-modal">
        <CoreTwoColumnsRow class="mb-1">
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    :condensed="true"
                >
                    <span>{{ $t('§equipment.equipment_connection_type') }}</span>
                </CoreBorderWrapper>
            </template>
            <template #right>
                <CoreDropdown
                    v-model="inputs.connection_type"
                    :options="$store.state.unit.connectivity_settings.connection_types"
                    :placeholder="$t('§ui.placeholder_equipment_connection_type')"
                    :fullWidth="true"
                    :condensed="true"
                />
            </template>
        </CoreTwoColumnsRow>
        
        <div v-if="inputs.connection_type==='washtec'">
            <CoreTwoColumnsRow class="mb-1">
                <template #left>
                    <CoreBorderWrapper
                        class="flex-grow-1 flex-shrink-1 flex-basis-45"
                        :condensed="true"
                    >
                        <span>{{ $t('§washtec_connection.username') }}</span>
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <CoreStringInput
                        v-model="inputs.washtec_username"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                        placeholder="§ui.placeholder_equipment_connection_type"
                        :fullWidth="true"
                        :condensed="true"
                    />
                </template>
            </CoreTwoColumnsRow>
            <CoreTwoColumnsRow class="mb-1">
                <template #left>
                    <CoreBorderWrapper
                        class="flex-grow-1 flex-shrink-1 flex-basis-45"
                        :condensed="true"
                    >
                        <span>{{ $t('§washtec_connection.password') }}</span>
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <CoreStringInput
                        v-model="inputs.washtec_password"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                        placeholder="§ui.placeholder_equipment_connection_detail"
                        :fullWidth="true"
                        :condensed="true"
                    />
                </template>
            </CoreTwoColumnsRow>
            <CoreTwoColumnsRow class="mb-1">
                <template #left>
                    <CoreBorderWrapper
                        class="flex-grow-1 flex-shrink-1 flex-basis-45"
                        :condensed="true"
                    >
                        <span>{{ $t('§washtec_connection.interaction_uid') }}</span>
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <CoreStringInput
                        v-model="inputs.washtec_interaction_uid"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                        placeholder="§ui.placeholder_equipment_connection_detail"
                        :fullWidth="true"
                        :condensed="true"
                    />
                </template>
            </CoreTwoColumnsRow>
            <CoreTwoColumnsRow class="mb-1">
                <template #left>
                    <CoreBorderWrapper
                        class="flex-grow-1 flex-shrink-1 flex-basis-45"
                        :condensed="true"
                    >
                        <span>{{ $t('§washtec_connection.machine_id') }}</span>
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <CoreStringInput
                        v-model="inputs.washtec_machine_id"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                        placeholder="§ui.placeholder_equipment_connection_detail"
                        :fullWidth="true"
                        :condensed="true"
                    />
                </template>
            </CoreTwoColumnsRow>
            <CoreTwoColumnsRow class="mb-1">
                <template #left>
                    <CoreBorderWrapper
                        class="flex-grow-1 flex-shrink-1 flex-basis-45"
                        :condensed="true"
                    >
                        <span>{{ $t('§washtec_connection.as_of') }}</span>
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <CoreStringInput
                        v-model="inputs.washtec_as_of"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                        placeholder="YYYY-MM-DD"
                        :fullWidth="true"
                        :condensed="true"
                    />
                </template>
            </CoreTwoColumnsRow>
        </div>
        <JsonEditorVue v-else
            v-model="inputs.configuration"
            :stringified="false"
            mode="text"
            class="json-editor mb-5"
            :readOnly="isJsonDisabled"
        />

        <CoreButtonPrimary
            label="§ui_button.save"
            icon="pi-check"
            @click="save"
            :disabled="isSaveDisabled"
        />
    </div>
</template>

<script>
import JsonEditorVue from 'json-editor-vue'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'
import CoreDropdown from '@/components/core/CoreDropdown.vue'

export default {
    name: 'EquipmentConnectivityModal',

    components: {
        JsonEditorVue,
        CoreTwoColumnsRow,
        CoreBorderWrapper,
        CoreStringInput,
        CoreButtonPrimary,
        CoreButtonSecondary,
        CoreDropdown,
    },

    data() {
        return {
            unit: null,
            inputs: {
                connection_type: '',
                configuration: '',
                washtec_username: '',
                washtec_password: '',
                washtec_interaction_uid: '',
                washtec_machine_id: '',
                washtec_as_of: '',
            },
        }
    },

    computed: {
        isSaveDisabled() {
            return false
        },
        isJsonDisabled() {
            return this.inputs.connection_type === ''
        },
    },

    mounted() {
        this.setData()
    },

    methods: {
        setData() {
            this.unit = this.$store.state.unit.inputs.id
            this.inputs.connection_type =
                this.$store.state.unit.connectivity_settings.connection_type
            this.inputs.configuration = JSON.parse(
                this.$store.state.unit.connectivity_settings.configuration
            )
            if(this.inputs.connection_type === 'washtec')
            {
                this.inputs.washtec_username = this.inputs.configuration['username']
                this.inputs.washtec_password = this.inputs.configuration['password']
                this.inputs.washtec_interaction_uid = this.inputs.configuration['interaction_uid']
                this.inputs.washtec_machine_id = this.inputs.configuration['machine_id']
                this.inputs.washtec_as_of = this.inputs.configuration['as_of']
            }
        },

        async save() {
            var configuration = JSON.stringify(this.inputs.configuration)
            if(this.inputs.connection_type === 'washtec')
            {
                configuration = JSON.stringify({
                    username: this.inputs.washtec_username,
                    password: this.inputs.washtec_password,
                    interaction_uid: this.inputs.washtec_interaction_uid,
                    machine_id: this.inputs.washtec_machine_id,
                    as_of: this.inputs.washtec_as_of,
                })
            }
            await this.$store.dispatch('unit/saveConnectivity', {
                vm: this,
                id: this.$store.state.unit.inputs.id,
                connection_type: this.inputs.connection_type,
                configuration: configuration,
            })

            this.$store.commit('modal/SET_CLOSE')
        },
    },
}
</script>

<style lang="scss">
.equipment-connectivity-modal {
    .json-editor {
        & .jse-json-node.jse-root.svelte-6mjnsm.svelte-6mjnsm.svelte-6mjnsm.svelte-6mjnsm,
        .jse-main.svelte-ybuk0j {
            min-height: 500px;
        }
    }
}
</style>
