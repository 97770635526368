import Vue from 'vue'
import VueRouter from 'vue-router'

import { store } from '../store'
import { blockForRoles } from './middleware'

Vue.use(VueRouter)

const routes = [
    {
        path: '/app/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home/Home.vue'),
        props: true,
        meta: { requiresAuth: true },
    },

    {
        path: '/app/user',
        name: 'UserViewsWrapper',
        component: () =>
            import(
                /* webpackChunkName: "user-views-wrapper" */ '@/views/User/UserViewsWrapper.vue'
            ),
        props: true,
        meta: { requiresAuth: true },

        children: [
            {
                path: 'profile',
                name: 'UserProfile',
                component: () =>
                    import(/* webpackChunkName: "user-profile" */ '@/views/User/UserProfile.vue'),
                props: true,
            },
            {
                path: 'change-password',
                name: 'UserChangePassword',
                component: () =>
                    import(
                        /* webpackChunkName: "user-change-password" */ '@/views/User/UserChangePassword.vue'
                    ),
                props: true,
            },
        ],
    },

    // ######################     MASTER DATA     ######################

    {
        path: '/app/client',
        name: 'ClientViewsWrapper',
        component: () =>
            import(
                /* webpackChunkName: "client-views-wrapper" */ '@/views/Client/ClientViewsWrapper.vue'
            ),
        props: true,
        meta: { requiresAuth: true },

        children: [
            {
                path: 'index',
                name: 'ClientIndex',
                component: () =>
                    import(/* webpackChunkName: "client-index" */ '@/views/Client/ClientIndex.vue'),
                props: true,
            },
            {
                path: 'create',
                name: 'ClientCreate',
                component: () =>
                    import(/* webpackChunkName: "client-create" */ '@/views/Client/ClientItem.vue'),
                props: true,
                beforeEnter: (to, from, next) => {
                    if (to.params.resetInputsState) {
                        store.commit('client/RESET_INPUTS')
                    }

                    if (to.params.cloneItem) {
                        store.commit('client/CLONE_ITEM')
                    }

                    next()
                },
            },
            {
                path: 'show/:id',
                name: 'ClientShow',
                component: () =>
                    import(/* webpackChunkName: "client-show" */ '@/views/Client/ClientItem.vue'),
                props: true,
                beforeEnter: (to, from, next) => {
                    store
                        .dispatch('client/getItemData', { id: to.params.id })
                        .then(() => {
                            next()
                        })
                        .catch((error) => {
                            console.error('Error fetching data:', error)
                            next(false)
                        })
                },
            },
        ],
    },

    {
        path: '/app/contract',
        name: 'ContractViewsWrapper',
        component: () =>
            import(
                /* webpackChunkName: "contract-views-wrapper" */ '@/views/Contract/ContractViewsWrapper.vue'
            ),
        props: true,
        meta: { requiresAuth: true },

        children: [
            {
                path: 'index',
                name: 'ContractIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "contract-index" */ '@/views/Contract/ContractIndex.vue'
                    ),
                props: true,
            },
            {
                path: 'show/:id',
                name: 'ContractShow',
                component: () =>
                    import(
                        /* webpackChunkName: "contract-show" */ '@/views/Contract/ContractShow.vue'
                    ),
                props: true,
            },
            {
                path: 'update/:id',
                name: 'ContractUpdate',
                component: () =>
                    import(
                        /* webpackChunkName: "contract-update" */ '@/views/Contract/ContractUpdate.vue'
                    ),
                props: true,
            },
            {
                path: 'invoices/:id',
                name: 'ContractInvoices',
                component: () =>
                    import(
                        /* webpackChunkName: "contract-invoices" */ '@/views/Contract/ContractInvoices.vue'
                    ),
                props: true,
            },
            {
                path: 'files/:id',
                name: 'ContractFiles',
                component: () =>
                    import(
                        /* webpackChunkName: "contract-files" */ '@/views/Contract/ContractFiles.vue'
                    ),
                props: true,
            },
            {
                path: 'kyc/:id',
                name: 'ContractKycForm',
                component: () =>
                    import(
                        /* webpackChunkName: "contract-kyc-form" */ '@/views/Contract/ContractKycForm.vue'
                    ),
                props: true,
            },
        ],
    },

    {
        path: '/app/token',
        name: 'TokenViewsWrapper',
        component: () =>
            import(
                /* webpackChunkName: "token-views-wrapper" */ '@/views/Token/TokenViewsWrapper.vue'
            ),
        props: true,
        meta: { requiresAuth: true },

        children: [
            {
                path: 'index',
                name: 'TokenIndex',
                component: () =>
                    import(/* webpackChunkName: "token-index" */ '@/views/Token/TokenIndex.vue'),
                props: true,
            },
            {
                path: 'create',
                name: 'TokenCreate',
                component: () =>
                    import(/* webpackChunkName: "token-create" */ '@/views/Token/TokenCreate.vue'),
                props: true,
            },
        ],
    },

    {
        path: '/app/unit',
        name: 'UnitViewsWrapper',
        component: () =>
            import(
                /* webpackChunkName: "unit-views-wrapper" */ '@/views/Unit/UnitViewsWrapper.vue'
            ),
        props: true,
        meta: { requiresAuth: true },

        children: [
            {
                path: 'index',
                name: 'UnitIndex',
                component: () =>
                    import(/* webpackChunkName: "unit-index" */ '@/views/Unit/UnitIndex.vue'),
                props: true,
            },
            {
                path: 'index-inactive',
                name: 'UnitIndexInactive',
                component: () =>
                    import(/* webpackChunkName: "unit-index" */ '@/views/Unit/UnitIndex.vue'),
                props: true,
            },
            {
                path: 'show/:id',
                name: 'UnitShow',
                component: () =>
                    import(/* webpackChunkName: "unit-show" */ '@/views/Unit/UnitShow.vue'),
                props: true,
            },
            {
                path: 'utilization/:id',
                name: 'UnitUtilization',
                component: () =>
                    import(
                        /* webpackChunkName: "unit-utilization" */ '@/views/Unit/UnitUtilization.vue'
                    ),
                props: true,
            },
        ],
    },

    {
        path: '/app/unit-type',
        name: 'UnitTypeViewsWrapper',
        component: () =>
            import(
                /* webpackChunkName: "unit-type-views-wrapper" */ '@/views/UnitType/UnitTypeViewsWrapper.vue'
            ),
        props: true,
        meta: { requiresAuth: true },

        children: [
            {
                path: 'index',
                name: 'UnitTypeIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "unit-type-index" */ '@/views/UnitType/UnitTypeIndex.vue'
                    ),
                props: true,
            },
            {
                path: 'create',
                name: 'UnitTypeCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "unit-type-create" */ '@/views/UnitType/UnitTypeCreate.vue'
                    ),
                props: true,
            },
            {
                path: 'show/:id',
                name: 'UnitTypeShow',
                component: () =>
                    import(
                        /* webpackChunkName: "unit-type-show" */ '@/views/UnitType/UnitTypeShow.vue'
                    ),
                props: true,
            },
        ],
    },

    // ######################     CLICK AND FINANCE     ######################

    {
        path: '/app/click-and-finance',
        name: 'ClickAndFinance',
        component: () =>
            import(
                /* webpackChunkName: "click-and-finance" */ '@/views/ClickAndFinance/ClickAndFinance.vue'
            ),
        props: true,
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
            if (store.state.showMobileNavigation) {
                store.commit('TOGGLE_MOBILE_NAVIGATION', {
                    menuOffsetTop: 0,
                })
            }

            blockForRoles(['admin'], '/app/not-found')(to, from, next)
        },
    },

    // ######################     USERS     ######################

    {
        path: '/app/users',
        name: 'UsersViewsWrapper',
        component: () =>
            import(
                /* webpackChunkName: "users-views-wrapper" */ '@/views/Users/UsersViewsWrapper.vue'
            ),
        props: true,
        meta: { requiresAuth: true },

        children: [
            {
                path: 'index',
                name: 'UsersIndex',
                component: () =>
                    import(/* webpackChunkName: "users-index" */ '@/views/Users/UsersIndex.vue'),
                props: true,
            },
            {
                path: 'invite',
                name: 'UsersInvite',
                component: () =>
                    import(/* webpackChunkName: "users-invite" */ '@/views/Users/UsersInvite.vue'),
                props: true,
            },
            {
                path: 'invites-index',
                name: 'UsersInvitesIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "users-invite-index" */ '@/views/Users/UsersInvitesIndex.vue'
                    ),
                props: true,
            },
            {
                path: 'permissions',
                name: 'UsersPermissions',
                component: () =>
                    import(
                        /* webpackChunkName: "users-permissions" */ '@/views/Users/UsersPermissions.vue'
                    ),
                props: true,
            },
        ],
    },

    // ######################     SERVICES     ######################

    {
        path: '/app/services',
        name: 'ServicesViewsWrapper',
        component: () =>
            import(
                /* webpackChunkName: "services-views-wrapper" */ '@/views/Services/ServicesViewsWrapper.vue'
            ),
        props: true,
        meta: { requiresAuth: true },

        children: [
            {
                path: 'overview',
                name: 'ServicesIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "services-index" */ '@/views/Services/ServicesIndex.vue'
                    ),
                props: true,
            },
            {
                path: 'create',
                name: 'ServicesCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "services-create" */ '@/views/Services/ServicesCreate.vue'
                    ),
                props: true,
            },
            {
                path: 'upload-protocols',
                name: 'ServicesUploadProtocol',
                component: () =>
                    import(
                        /* webpackChunkName: "services-upload-protocol" */ '@/views/Services/ServicesUploadProtocol.vue'
                    ),
                props: true,
            },
        ],
    },

    // ######################     ADMIN SECTION     ######################

    {
        path: '/app/risk-management',
        name: 'RiskManagementViewsWrapper',
        component: () =>
            import(
                /* webpackChunkName: "risk-management-views-wrapper" */ '@/views/RiskManagement/RiskManagementViewsWrapper.vue'
            ),
        props: true,
        meta: { requiresAuth: true },

        children: [
            {
                path: 'risk-management-index',
                name: 'RiskManagementIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "risk-management-index" */ '@/views/RiskManagement/RiskManagementIndex.vue'
                    ),
                props: true,
            },
            {
                path: 'risk-management-dashboard',
                name: 'RiskManagementDashboard',
                component: () =>
                    import(
                        /* webpackChunkName: "risk-management-dashboard" */ '@/views/RiskManagement/RiskManagementDashboard.vue'
                    ),
                props: true,
            },
            {
                path: 'watchlist-index',
                name: 'WatchlistIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "watchlist-index" */ '@/views/RiskManagement/WatchlistIndex.vue'
                    ),
                props: true,
            },
            {
                path: 'watchlist-dashboard',
                name: 'WatchlistDashboard',
                component: () =>
                    import(
                        /* webpackChunkName: "watchlist-dashboard" */ '@/views/RiskManagement/WatchlistDashboard.vue'
                    ),
                props: true,
            },
        ],
    },

    {
        path: '/app/operations-management',
        name: 'OperationsManagementViewsWrapper',
        component: () =>
            import(
                /* webpackChunkName: "operations-management-views-wrapper" */ '@/views/OperationsManagement/OperationsManagementViewsWrapper.vue'
            ),
        props: true,
        meta: { requiresAuth: true },

        children: [
            {
                path: 'missing-contract-documents-overview',
                name: 'MissingContractDocumentsOverview',
                component: () =>
                    import(
                        /* webpackChunkName: "missing-contract-documents-overview" */ '@/views/OperationsManagement/MissingContractDocumentsOverview.vue'
                    ),
                props: true,
            },
        ],
    },

    {
        path: '/app/invoices',
        name: 'InvoicesViewsWrapper',
        component: () =>
            import(
                /* webpackChunkName: "invoice-views-wrapper" */ '@/views/Invoices/InvoicesViewsWrapper.vue'
            ),
        props: true,
        meta: { requiresAuth: true },

        children: [
            {
                path: 'index',
                name: 'InvoicesIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "invoice-index" */ '@/views/Invoices/InvoicesIndex.vue'
                    ),
                props: true,
            },
            {
                path: 'show/:id',
                name: 'InvoicesShow',
                component: () =>
                    import(
                        /* webpackChunkName: "invoice-index" */ '@/views/Invoices/InvoicesShow.vue'
                    ),
                props: true,
            },
        ],
    },

    {
        path: '/app/admin',
        name: 'AdminViewsWrapper',
        component: () =>
            import(
                /* webpackChunkName: "admin-views-wrapper" */ '@/views/Admin/AdminViewsWrapper.vue'
            ),
        props: true,
        meta: { requiresAuth: true },

        children: [
            {
                path: 'caf-settings',
                name: 'AdminCafSettings',
                component: () =>
                    import(
                        /* webpackChunkName: "admin-caf-settings" */ '@/views/Admin/AdminCafSettings.vue'
                    ),
                props: true,
            },
            {
                path: 'country-index',
                name: 'AdminCountryIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "admin-country-index" */ '@/views/Admin/AdminCountryIndex.vue'
                    ),
                props: true,
            },
            {
                path: 'industry-index',
                name: 'AdminIndustryIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "admin-industry-index" */ '@/views/Admin/AdminIndustryIndex.vue'
                    ),
                props: true,
            },
            {
                path: 'invoice-settings',
                name: 'AdminInvoiceSettings',
                component: () =>
                    import(
                        /* webpackChunkName: "admin-invoice-settings" */ '@/views/Admin/AdminInvoiceSettings.vue'
                    ),
                props: true,
            },
            {
                path: 'billing-units-index',
                name: 'BillingUnitIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "billing-unit-index" */ '@/views/Admin/BillingUnitIndex.vue'
                    ),
                props: true,
            },
            {
                path: 'emails-compliance-settings',
                name: 'AdminEmailsComplianceSettings',
                component: () =>
                    import(
                        /* webpackChunkName: "admin-emails-compliance-settings" */ '@/views/Admin/AdminEmailsComplianceSettings.vue'
                    ),
                props: true,
            },
        ],
    },

    {
        path: '/app/not-found',
        name: 'PageNotFound',
        component: () =>
            import(/* webpackChunkName: "page-not-found" */ '@/views/Home/PageNotFound.vue'),
        props: true,
        meta: { requiresAuth: true },
    },

    {
        path: '/app/*',
        redirect: '/app/not-found',
    },
]

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'link--active',
    routes,
})

router.beforeEach((to, from, next) => {
    if (from.name) {
        to.meta.isReloaded = false
    } else {
        to.meta.isReloaded = true
    }
    next()
})

export default router
