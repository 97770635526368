export const LOCALE_NAMES = {
    enGB: 'en-GB',
    enIE: 'en-IE',
    enUS: 'en-US',
    deDE: 'de-DE',
}

export const localeOptions = (pdfLanguages = false) => {
    const localeObjects = [
        { value: LOCALE_NAMES.enGB, label: '§user.locale_option_en_GB' },
        { value: LOCALE_NAMES.enIE, label: '§user.locale_option_en_IE' },
        { value: LOCALE_NAMES.deDE, label: '§user.locale_option_de_DE' },
    ]

    if (!pdfLanguages) {
        localeObjects.push({ value: LOCALE_NAMES.enUS, label: '§user.locale_option_en_US' })
    }

    return localeObjects
}

const dateFormatOptionsHelper = (date, locale) => {
    return new Intl.DateTimeFormat(locale).format(date)
}
export const dateFormatOptions = (vm) => {
    const date = Date.now()
    return [
        { value: LOCALE_NAMES.enGB, label: `${vm.$t('§locale.en_GB')} - ${dateFormatOptionsHelper(date,'en-GB')}` },
        { value: LOCALE_NAMES.enIE, label: `${vm.$t('§locale.en_IE')} - ${dateFormatOptionsHelper(date,'en-IE')}` },
        { value: LOCALE_NAMES.deDE, label: `${vm.$t('§locale.de_DE')} - ${dateFormatOptionsHelper(date,'de-DE')}` },
        { value: LOCALE_NAMES.enUS, label: `${vm.$t('§locale.en_US')} - ${dateFormatOptionsHelper(date,'en-US')}` },
    ]
}


const numberFormatOptionsHelper = (value, locale, currencyCode, precision) => {
    return new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: precision,
                maximumFractionDigits: precision,
            }).format(value)
}
export const numberFormatOptions = (vm, currency_code) => {
    const number = 123456.789
    return [
        { value: LOCALE_NAMES.enGB, label: `${vm.$t('§locale.en_GB')} - ${numberFormatOptionsHelper(number,'en-GB',currency_code,2)}` },
        { value: LOCALE_NAMES.enIE, label: `${vm.$t('§locale.en_IE')} - ${numberFormatOptionsHelper(number,'en-IE',currency_code,2)}` },
        { value: LOCALE_NAMES.deDE, label: `${vm.$t('§locale.de_DE')} - ${numberFormatOptionsHelper(number,'de-DE',currency_code,2)}` },
        { value: LOCALE_NAMES.enUS, label: `${vm.$t('§locale.en_US')} - ${numberFormatOptionsHelper(number,'en-US',currency_code,2)}` },
    ]
}

export const numberFormatPlaceholder = (locale) => {
    switch (locale) {
        case LOCALE_NAMES.enGB:
            return '§ui.placeholder_input_number_en_GB';
        case LOCALE_NAMES.enIE:
            return '§ui.placeholder_input_number_en_IE';
        case LOCALE_NAMES.deDE:
            return '§ui.placeholder_input_number_de_DE';
        case LOCALE_NAMES.enUS:
            return '§ui.placeholder_input_number_en_US';
        default:
            return '§ui.placeholder_input_number_en_GB';
    }
};
